.root {
    // display: flex !important;
    // flex-wrap: wrap !important;
}
.formControl {
    min-width: 100px !important;
    display: flex;
    justify-content: end;
    align-items: center;
}
.languageLabel {
    margin-top: 4px;
    color: #000 !important;
    font-size: 0.6rem !important;
    font-weight: 400 !important;
    line-height: 1;
}
.languageSelect {
    color: #000 !important;
    margin-top: 10px !important;
    font-size: 0.8rem !important;
    &::before {
        border-bottom: 1px solid #000 !important;
    }
    & > svg {
        color: #000 !important;
    }
    & > div {
        padding: 0px 0 3px !important;
    }
}
