
// .bg-image {
//   background-size: cover;
//   overflow: hidden;
//   background-repeat: no-repeat;
//   filter: blur(8px);
//   -webkit-filter: blur(8px);
//   height: 100%;
// }
#root {
  background-color: var(--primary);
}

.content-wapper {
  // min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // // background-color: rgb(0,0,0); /* Fallback color */
  // // background-color: rgba(0,0,0, 0.4);
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // z-index: 2;
  // width: 100%;
}

.container-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  max-width: 1080px;
  background-color: #fafafa;
  flex-grow: 1;
  padding: 30px 30px 40px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  // max-width: 1100px;
  // background: rgba(255, 255, 255, 0.8);
  // flex-grow: 1;
  // padding: 30px 30px 40px;
  // box-shadow: 1px 2px 1px 2px rgba(0, 0, 0, 0.16);
  // border-radius: 5px;
}

.header-title {
  margin-bottom: 20px;
  display: flex;
  height: 90px;
  line-height: 90px;
  align-items: center;
  justify-content: center;
}

.header {
  font-size: 24px;
  font-family: sans;
  font-weight: 500;
  // color: var(--primary) !important;
  // text-align: center;
  padding-left: 10px;
  margin: 0;
  text-transform: uppercase;
}

.content {
  min-height: 100px;
}

.footer {
  display: flex;
  justify-content: center;
}

.card-wrapper {
  padding: 4px;
  > button {
    min-height: 60px;
  }
}
@media only screen and (max-width: 860px) {
  .container-wrapper {  
    padding: 20px;
  }
  .header-title {
    display: block;
    text-align: center;
    height: unset;
    line-height: unset;
    .header {
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container-wrapper > .container > .row {
    justify-content: start;
  }
}