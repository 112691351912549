:root,
::after,
::before {
    --primary: #006778;
    --secondary: #f7ba61;
    --error: #f44336;
    --bg-default: #fafafa;
    --bg-paper: #fff;
    --text-body: rgba(0, 0, 0, 0.87);
    --text-muted: rgba(0, 0, 0, 0.54);
    --text-hint: rgba(0, 0, 0, 0.38);
    --font: Roboto,"Helvetica Neue",sans-serif;
}

@import "./variables";
@import "./mixins";
@import "./reboot";
@import "./utilities/utilities";
@import "globitsStyles.scss";
