:root,
::after,
::before {
  --primary: #006778;
  --secondary: #f7ba61;
  --error: #f44336;
  --bg-default: #fafafa;
  --bg-paper: #fff;
  --text-body: rgba(0, 0, 0, 0.87);
  --text-muted: rgba(0, 0, 0, 0.54);
  --text-hint: rgba(0, 0, 0, 0.38);
  --font: Roboto,"Helvetica Neue",sans-serif; }

#root,
body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; }

html {
  font-size: 16px; }

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

div,
a {
  box-sizing: border-box; }

img {
  max-width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
  color: var(--text-body) !important;
  font-weight: 500; }

.layout1,
.layout2,
.MuiPaper-root,
.MuiTableCell-body,
.egret-customizer {
  color: var(--text-body) !important; }

.m-0 {
  margin: 0px !important; }

.mt-0 {
  margin-top: 0px !important; }

.mr-0 {
  margin-right: 0px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.ml-0 {
  margin-left: 0px !important; }

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.p-0 {
  padding: 0px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pr-0 {
  padding-right: 0px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.pl-0 {
  padding-left: 0px !important; }

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.m-2 {
  margin: 2px !important; }

.mt-2 {
  margin-top: 2px !important; }

.mr-2 {
  margin-right: 2px !important; }

.mb-2 {
  margin-bottom: 2px !important; }

.ml-2 {
  margin-left: 2px !important; }

.mx-2 {
  margin-left: 2px !important;
  margin-right: 2px !important; }

.my-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important; }

.p-2 {
  padding: 2px !important; }

.pt-2 {
  padding-top: 2px !important; }

.pr-2 {
  padding-right: 2px !important; }

.pb-2 {
  padding-bottom: 2px !important; }

.pl-2 {
  padding-left: 2px !important; }

.px-2 {
  padding-left: 2px !important;
  padding-right: 2px !important; }

.py-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important; }

.m-4 {
  margin: 4px !important; }

.mt-4 {
  margin-top: 4px !important; }

.mr-4 {
  margin-right: 4px !important; }

.mb-4 {
  margin-bottom: 4px !important; }

.ml-4 {
  margin-left: 4px !important; }

.mx-4 {
  margin-left: 4px !important;
  margin-right: 4px !important; }

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }

.p-4 {
  padding: 4px !important; }

.pt-4 {
  padding-top: 4px !important; }

.pr-4 {
  padding-right: 4px !important; }

.pb-4 {
  padding-bottom: 4px !important; }

.pl-4 {
  padding-left: 4px !important; }

.px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important; }

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.m-8 {
  margin: 8px !important; }

.mt-8 {
  margin-top: 8px !important; }

.mr-8 {
  margin-right: 8px !important; }

.mb-8 {
  margin-bottom: 8px !important; }

.ml-8 {
  margin-left: 8px !important; }

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important; }

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }

.p-8 {
  padding: 8px !important; }

.pt-8 {
  padding-top: 8px !important; }

.pr-8 {
  padding-right: 8px !important; }

.pb-8 {
  padding-bottom: 8px !important; }

.pl-8 {
  padding-left: 8px !important; }

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important; }

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.m-10 {
  margin: 10px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.p-10 {
  padding: 10px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pl-10 {
  padding-left: 10px !important; }

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.m-12 {
  margin: 12px !important; }

.mt-12 {
  margin-top: 12px !important; }

.mr-12 {
  margin-right: 12px !important; }

.mb-12 {
  margin-bottom: 12px !important; }

.ml-12 {
  margin-left: 12px !important; }

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important; }

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important; }

.p-12 {
  padding: 12px !important; }

.pt-12 {
  padding-top: 12px !important; }

.pr-12 {
  padding-right: 12px !important; }

.pb-12 {
  padding-bottom: 12px !important; }

.pl-12 {
  padding-left: 12px !important; }

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.m-16 {
  margin: 16px !important; }

.mt-16 {
  margin-top: 16px !important; }

.mr-16 {
  margin-right: 16px !important; }

.mb-16 {
  margin-bottom: 16px !important; }

.ml-16 {
  margin-left: 16px !important; }

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important; }

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.p-16 {
  padding: 16px !important; }

.pt-16 {
  padding-top: 16px !important; }

.pr-16 {
  padding-right: 16px !important; }

.pb-16 {
  padding-bottom: 16px !important; }

.pl-16 {
  padding-left: 16px !important; }

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important; }

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.m-20 {
  margin: 20px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.p-20 {
  padding: 20px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pl-20 {
  padding-left: 20px !important; }

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.m-24 {
  margin: 24px !important; }

.mt-24 {
  margin-top: 24px !important; }

.mr-24 {
  margin-right: 24px !important; }

.mb-24 {
  margin-bottom: 24px !important; }

.ml-24 {
  margin-left: 24px !important; }

.mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.p-24 {
  padding: 24px !important; }

.pt-24 {
  padding-top: 24px !important; }

.pr-24 {
  padding-right: 24px !important; }

.pb-24 {
  padding-bottom: 24px !important; }

.pl-24 {
  padding-left: 24px !important; }

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.m-28 {
  margin: 28px !important; }

.mt-28 {
  margin-top: 28px !important; }

.mr-28 {
  margin-right: 28px !important; }

.mb-28 {
  margin-bottom: 28px !important; }

.ml-28 {
  margin-left: 28px !important; }

.mx-28 {
  margin-left: 28px !important;
  margin-right: 28px !important; }

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important; }

.p-28 {
  padding: 28px !important; }

.pt-28 {
  padding-top: 28px !important; }

.pr-28 {
  padding-right: 28px !important; }

.pb-28 {
  padding-bottom: 28px !important; }

.pl-28 {
  padding-left: 28px !important; }

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important; }

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important; }

.m-30 {
  margin: 30px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.ml-30 {
  margin-left: 30px !important; }

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.p-30 {
  padding: 30px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pl-30 {
  padding-left: 30px !important; }

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.m-32 {
  margin: 32px !important; }

.mt-32 {
  margin-top: 32px !important; }

.mr-32 {
  margin-right: 32px !important; }

.mb-32 {
  margin-bottom: 32px !important; }

.ml-32 {
  margin-left: 32px !important; }

.mx-32 {
  margin-left: 32px !important;
  margin-right: 32px !important; }

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important; }

.p-32 {
  padding: 32px !important; }

.pt-32 {
  padding-top: 32px !important; }

.pr-32 {
  padding-right: 32px !important; }

.pb-32 {
  padding-bottom: 32px !important; }

.pl-32 {
  padding-left: 32px !important; }

.px-32 {
  padding-left: 32px !important;
  padding-right: 32px !important; }

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important; }

.m-36 {
  margin: 36px !important; }

.mt-36 {
  margin-top: 36px !important; }

.mr-36 {
  margin-right: 36px !important; }

.mb-36 {
  margin-bottom: 36px !important; }

.ml-36 {
  margin-left: 36px !important; }

.mx-36 {
  margin-left: 36px !important;
  margin-right: 36px !important; }

.my-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important; }

.p-36 {
  padding: 36px !important; }

.pt-36 {
  padding-top: 36px !important; }

.pr-36 {
  padding-right: 36px !important; }

.pb-36 {
  padding-bottom: 36px !important; }

.pl-36 {
  padding-left: 36px !important; }

.px-36 {
  padding-left: 36px !important;
  padding-right: 36px !important; }

.py-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important; }

.m-40 {
  margin: 40px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.ml-40 {
  margin-left: 40px !important; }

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.p-40 {
  padding: 40px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pl-40 {
  padding-left: 40px !important; }

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.m-44 {
  margin: 44px !important; }

.mt-44 {
  margin-top: 44px !important; }

.mr-44 {
  margin-right: 44px !important; }

.mb-44 {
  margin-bottom: 44px !important; }

.ml-44 {
  margin-left: 44px !important; }

.mx-44 {
  margin-left: 44px !important;
  margin-right: 44px !important; }

.my-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important; }

.p-44 {
  padding: 44px !important; }

.pt-44 {
  padding-top: 44px !important; }

.pr-44 {
  padding-right: 44px !important; }

.pb-44 {
  padding-bottom: 44px !important; }

.pl-44 {
  padding-left: 44px !important; }

.px-44 {
  padding-left: 44px !important;
  padding-right: 44px !important; }

.py-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important; }

.m-48 {
  margin: 48px !important; }

.mt-48 {
  margin-top: 48px !important; }

.mr-48 {
  margin-right: 48px !important; }

.mb-48 {
  margin-bottom: 48px !important; }

.ml-48 {
  margin-left: 48px !important; }

.mx-48 {
  margin-left: 48px !important;
  margin-right: 48px !important; }

.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important; }

.p-48 {
  padding: 48px !important; }

.pt-48 {
  padding-top: 48px !important; }

.pr-48 {
  padding-right: 48px !important; }

.pb-48 {
  padding-bottom: 48px !important; }

.pl-48 {
  padding-left: 48px !important; }

.px-48 {
  padding-left: 48px !important;
  padding-right: 48px !important; }

.py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }

@media screen and (max-width: 767px) {
  .pb-30 {
    padding-bottom: 16px !important; } }

.pb-2 {
  padding-bottom: 2px; }

.pb-3 {
  padding-bottom: 3px; }

.px-80 {
  padding-right: 80px;
  padding-left: 80px; }
  @media screen and (max-width: 460px) {
    .px-80 {
      padding-right: 16px;
      padding-left: 16px; } }

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.py-2 {
  padding-top: 2px;
  padding-bottom: 2px; }

.p-sm-30 {
  padding: 30px; }
  @media screen and (max-width: 767px) {
    .p-sm-30 {
      padding: 16px; } }

.px-sm-30 {
  padding: 0px 30px; }
  @media screen and (max-width: 767px) {
    .px-sm-30 {
      padding: 0px 16px; } }

.pr-sm-30 {
  padding-right: 30px !important; }
  @media screen and (max-width: 767px) {
    .pr-sm-30 {
      padding-right: 16px !important; } }

.p-sm-24 {
  padding: 24px !important; }
  @media screen and (max-width: 767px) {
    .p-sm-24 {
      padding: 16px !important; } }

.px-sm-24 {
  padding: 0px 24px !important; }
  @media screen and (max-width: 767px) {
    .px-sm-24 {
      padding: 0px 16px !important; } }

.pt-sm-24 {
  padding-top: 24px !important; }
  @media screen and (max-width: 767px) {
    .pt-sm-24 {
      padding-top: 16px !important; } }

.pl-sm-24 {
  padding-left: 24px !important; }
  @media screen and (max-width: 767px) {
    .pl-sm-24 {
      padding: 12px !important; } }

.m-auto {
  margin: auto !important; }

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mb-38 {
  margin-bottom: 38px; }

.mb-14 {
  margin-bottom: 14px !important; }

.mb-1 {
  margin-bottom: 1px; }

.mb-2 {
  margin-bottom: 2px; }

.ml--12 {
  margin-left: -12px; }

.m-sm-30 {
  margin: 30px; }
  @media screen and (max-width: 767px) {
    .m-sm-30 {
      margin: 16px; } }

.mb-sm-30 {
  margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .mb-sm-30 {
      margin-bottom: 16px; } }

.mx-sm-30 {
  margin-right: 30px;
  margin-left: 30px; }
  @media screen and (max-width: 767px) {
    .mx-sm-30 {
      margin-right: 16px;
      margin-left: 16px; } }

.w-80 {
  width: 80%; }

.w-100 {
  width: 100%; }

.w-220 {
  width: 220px; }

.h-100 {
  height: 100% !important; }

.h-150 {
  height: 150px !important; }

.h-320 {
  height: 320px; }

.h-100vh {
  height: 100vh; }

.h-100vh-80 {
  height: calc(100vh - 80px); }

.h-500 {
  height: 500px !important; }

.h-700 {
  height: 700px; }

.h-800 {
  height: 800px; }

.h-48 {
  height: 48px; }

.h-70 {
  height: 70px; }

.h-32 {
  height: 32px; }

.h-56 {
  height: 56px; }

.size-36 {
  height: 36px !important;
  width: 36px !important; }

.size-24 {
  height: 24px !important;
  width: 24px !important; }

.align-bottom {
  vertical-align: bottom !important; }

.bg-primary {
  background: var(--primary) !important; }

.bg-secondary {
  background: var(--secondary) !important; }

.bg-error {
  background: var(--error) !important; }

.bg-white {
  background: #fff !important;
  color: inherit; }

.bg-default {
  background: var(--bg-default) !important; }

.bg-paper {
  background: var(--bg-paper); }

.bg-light-gray {
  background: rgba(0, 0, 0, 0.08) !important; }

.bg-dark {
  background: #000000;
  color: #fff; }

.bg-light-dark {
  background: #212121;
  color: white; }

.bg-error {
  background: var(--error) !important;
  color: white !important; }

[class^="bg-light-"],
[class*=" bg-light-"] {
  position: relative;
  z-index: 0; }
  [class^="bg-light-"]::after,
  [class*=" bg-light-"]::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.15;
    z-index: -1;
    border-radius: 8px; }

.bg-light-primary::after {
  background: var(--primary); }

.bg-light-secondary {
  position: relative;
  z-index: 0; }
  .bg-light-secondary::after {
    background: var(--secondary); }

.bg-light-error {
  position: relative;
  z-index: 0; }
  .bg-light-error::after {
    background: var(--error); }

.bg-green {
  background: #08ad6c !important; }

.bg-light-green {
  background: rgba(8, 173, 108, 0.5) !important; }

.bg-transperant {
  background: transparent !important; }

.text-white {
  color: #fff !important; }

.text-muted-white {
  color: rgba(255, 255, 255, 0.54) !important; }

.text-light-white {
  color: rgba(255, 255, 255, 0.54) !important; }

.text-muted {
  color: var(--text-muted) !important; }

.text-hint {
  color: var(--text-hint) !important; }

.text-gray {
  color: rgba(0, 0, 0, 0.74) !important; }

.text-brand {
  color: var(--primary) !important; }

.text-primary {
  color: var(--primary) !important; }

.text-secondary {
  color: var(--secondary) !important; }

.text-green {
  color: #08ad6c !important; }

.text-error {
  color: var(--error) !important; }

.gray-on-hover {
  transition: background 250ms ease; }
  .gray-on-hover:hover {
    background: rgba(0, 0, 0, 0.054); }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5rem;
  line-height: 1.1;
  color: inherit; }

.h1,
h1 {
  font-size: 2rem; }

.h2,
h2 {
  font-size: 1.75rem; }

.h3,
h3 {
  font-size: 1.5rem; }

.h4,
h4 {
  font-size: 1.25rem; }

.h5,
h5 {
  font-size: 1rem; }

.h6,
h6 {
  font-size: 0.875rem; }

a {
  text-decoration: none;
  color: inherit; }

.caption {
  font: var(--font-caption); }

.subheading-1 {
  font: var(--font-subheading-1); }

.subheading-2 {
  font: var(--font-subheading-2); }

.heading {
  font: var(--font-heading); }

.title {
  font: var(--font-title); }

.display-1 {
  font: var(--font-display-1); }

.display-2 {
  font: var(--font-display-2); }

.display-3 {
  font: var(--font-display-3); }

.display-4 {
  font: var(--font-display-4); }

.capitalize {
  text-transform: capitalize !important; }

.uppercase {
  text-transform: uppercase !important; }

.lowercase {
  text-transform: lowercase !important; }

.font-weight-normal {
  font-weight: normal !important; }

.font-weight-300,
.font-weight-light {
  font-weight: 300 !important; }

.font-weight-500,
.font-weight-semi-bold {
  font-weight: 500 !important; }

.font-weight-600,
.font-weight-bold {
  font-weight: 600 !important; }

.font-style-italic {
  font-style: italic !important; }

.font-size-13 {
  font-size: 13px; }

.font-size-14 {
  font-size: 14px; }

.font-size-16 {
  font-size: 16px; }

.font-size-18 {
  font-size: 18px; }

.font-size-20 {
  font-size: 20px; }

.font-size-22 {
  font-size: 22px; }

.font-size-24 {
  font-size: 24px; }

.font-size-30 {
  font-size: 30px !important; }

.font-size-32 {
  font-size: 32px; }

.text-small {
  font-size: 0.8125rem !important; }

.white-space-pre-line {
  white-space: pre-wrap;
  word-break: break-word; }

.white-space-pre {
  white-space: pre; }

.fade-in {
  animation: fade-in 1s cubic-bezier(0.17, 0.67, 0.83, 0.67); }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes spin {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

.spin {
  animation: spin 3s infinite linear; }

.display-none {
  display: none; }

.display-block {
  display: block; }

.flex {
  display: flex; }

.flex-column {
  display: flex;
  flex-direction: column; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-start {
  justify-content: flex-start !important; }

.flex-center {
  justify-content: center; }

.flex-end {
  justify-content: flex-end; }

.flex-space-between {
  justify-content: space-between !important; }

.flex-space-around {
  justify-content: space-around; }

.flex-middle {
  align-items: center; }

.flex-top {
  align-items: flex-start; }

.flex-stretch {
  align-items: stretch; }

.flex-bottom {
  align-items: end; }

.flex-grow-1 {
  flex-grow: 1; }

.flex-grow-2 {
  flex-grow: 2; }

.flex-grow-3 {
  flex-grow: 3; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden; }

.scroll-y {
  overflow-x: hidden;
  overflow-y: scroll; }

.position-relative {
  position: relative; }

.position-bottom {
  position: absolute;
  bottom: 0; }

.text-center {
  text-align: center; }

.text-middle {
  vertical-align: middle; }

.text-bottom {
  vertical-align: bottom; }

.text-top {
  vertical-align: top !important; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

.x-center {
  left: 50%;
  transform: translateX(-50%); }

.y-center {
  top: 50%;
  transform: translateY(-50%); }

.cursor-pointer {
  cursor: pointer; }

.cursor-move {
  cursor: move; }

.border-radius-0 {
  border-radius: 0px !important;
  overflow: hidden; }

.border-radius-4 {
  border-radius: 4px !important;
  overflow: hidden; }

.border-radius-8 {
  border-radius: 8px !important;
  overflow: hidden; }

.border-radius-circle {
  border-radius: 50% !important;
  overflow: hidden; }

.border-none {
  border: none !important; }

.elevation-z0 {
  box-shadow: var(--elevation-z0); }

.elevation-z1 {
  box-shadow: var(--elevation-z1); }

.elevation-z2 {
  box-shadow: var(--elevation-z2); }

.elevation-z3 {
  box-shadow: var(--elevation-z3); }

.elevation-z4 {
  box-shadow: var(--elevation-z4); }

.elevation-z5 {
  box-shadow: var(--elevation-z5); }

.elevation-z6 {
  box-shadow: var(--elevation-z6); }

.elevation-z7 {
  box-shadow: var(--elevation-z7); }

.elevation-z8 {
  box-shadow: var(--elevation-z8); }

.elevation-z9 {
  box-shadow: var(--elevation-z9); }

.elevation-z10 {
  box-shadow: var(--elevation-z10); }

.elevation-z11 {
  box-shadow: var(--elevation-z11); }

.elevation-z12 {
  box-shadow: var(--elevation-z12); }

.elevation-z13 {
  box-shadow: var(--elevation-z13); }

.elevation-z14 {
  box-shadow: var(--elevation-z14); }

.elevation-z15 {
  box-shadow: var(--elevation-z15); }

.elevation-z16 {
  box-shadow: var(--elevation-z16); }

.elevation-z17 {
  box-shadow: var(--elevation-z17); }

.elevation-z18 {
  box-shadow: var(--elevation-z18); }

.elevation-z19 {
  box-shadow: var(--elevation-z19); }

.elevation-z20 {
  box-shadow: var(--elevation-z20); }

.elevation-z21 {
  box-shadow: var(--elevation-z21); }

.elevation-z22 {
  box-shadow: var(--elevation-z22); }

.elevation-z23 {
  box-shadow: var(--elevation-z23); }

.elevation-z24 {
  box-shadow: var(--elevation-z24); }

.bg-dotted {
  background: url("/assets/images/dots.png"), linear-gradient(90deg, #7467ef -19.83%, #ada5f6 189.85%);
  background-repeat: no-repeat;
  background-size: 100%; }

.circular-image-small {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden; }

.card {
  transition: all 0.3s ease; }
  .card:hover {
    box-shadow: var(--elevation-z12); }

.card-title {
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 500; }

.card-subtitle {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.54); }
  .theme-dark .card-subtitle {
    color: rgba(255, 255, 255, 0.54); }

@media screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none !important; } }

.VictoryContainer svg {
  height: 100% !important; }

.box-shadow-none {
  box-shadow: none !important; }

.circle-44 {
  height: 44px !important;
  width: 44px !important; }

.circle-32 {
  height: 32px !important;
  min-height: 32px !important;
  width: 32px !important; }
  .circle-32 .MuiFab-root {
    min-height: 32px !important; }
  .circle-32 .MuiIcon-root {
    font-size: 13px !important; }

.show-on-mobile {
  display: none !important; }
  @media screen and (max-width: 767px) {
    .show-on-mobile {
      display: inherit !important; } }

.hide-on-mobile {
  display: inherit; }
  @media screen and (max-width: 767px) {
    .hide-on-mobile {
      display: none; } }

.invisible-on-pc {
  visibility: hidden; }
  @media screen and (max-width: 767px) {
    .invisible-on-pc {
      visibility: visible; } }

.highlight-js pre {
  white-space: pre-line; }

.text-center {
  text-align: center !important; }

.btn {
  cursor: pointer;
  display: inline-block !important;
  font-weight: 400 !important;
  text-align: center !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border: 1px solid transparent !important;
  padding: 4px !important;
  font-size: 0.875rem !important;
  line-height: 1.2 !important;
  border-radius: 2px !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  min-width: unset !important;
  white-space: pre;
  text-transform: uppercase; }

.btn-primary {
  color: #fff !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important; }
  .btn-primary.Mui-disabled {
    opacity: 0.5; }

.gc-2 {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px; }

.title-primary {
  font-size: 1.35em;
  font-weight: 500;
  color: var(--primary) !important; }

.title-primary-d {
  font-size: 1.35em;
  font-weight: 500;
  color: #0d6674 !important;
  padding: 8px;
  background-color: #d8eef1;
  text-align: center; }

.box-primary-d {
  border: 1px solid #d8eef1;
  padding: 4px; }

.btn-primary-d {
  color: #fff !important;
  background-color: #2a80c8 !important;
  border-color: #2a80c8 !important; }

.btn-secondary {
  color: #fff !important;
  background-color: #696969 !important; }

.btn-secondary:hover {
  color: #fff !important;
  background-color: #000000c0 !important; }

.btn-success {
  color: #fff !important;
  background-color: #3f923f !important;
  border-color: #3f923f !important; }

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important; }

.btn-warning {
  color: #fff !important;
  background-color: #ffbe50 !important;
  border-color: #ffbe50 !important; }

.btn-info {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important; }

.btn-light {
  color: #212529 !important;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important; }

.btn-dark {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important; }

.btn-outlined {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  padding: 4px 24px !important; }

.btn-outlined-new {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  padding: 7.5px 24px !important; }

.btn-danger:hover {
  color: #fff !important;
  background-color: #c82333 !important;
  border-color: #bd2130 !important; }

.btn-warning:hover {
  color: #fff !important;
  background-color: #e0a800 !important;
  border-color: #d39e00 !important; }

.btn-info:hover {
  color: #fff !important;
  background-color: #138496 !important;
  border-color: #117a8b !important; }

.btn-primary:hover {
  color: #fff !important;
  background-color: #044450 !important;
  border-color: #044450 !important; }

.btn-primary-d:hover {
  color: #fff !important;
  background-color: #1f5f94;
  border-color: #1f5f94; }

.btn-success:hover {
  color: #fff !important;
  background-color: #218838 !important;
  border-color: #1e7e34 !important; }

.btn-light:hover {
  color: #212529 !important;
  background-color: #e2e6ea !important;
  border-color: #dae0e5 !important; }

.btn-dark:hover {
  color: #fff !important;
  background-color: #23272b !important;
  border-color: #1d2124 !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.MuiTypography-colorPrimary {
  color: #2196f3 !important; }

.MuiIcon-colorPrimary {
  color: #2196f3 !important; }

.text-primary-d2 {
  color: #2564a9 !important; }

.text-primary {
  color: var(--primary) !important; }

.bgc-primary-d1 {
  background-color: var(--primary) !important; }

.brc-primary-l2 {
  border-color: #d2e6f6 !important; }

.bgc-green-d1 {
  background-color: #36ad82 !important; }

.bgc-primary {
  background-color: #2196f3 !important; }

.brc-green-l2 {
  border-color: #d8f0e8 !important; }

.bgc-warning-d1 {
  background-color: #e19e2e !important; }

.text-orange-l2 {
  color: #ffc277 !important; }

.brc-warning-l2 {
  border-color: #fbeacd !important; }

.bgc-danger-tp1 {
  background-color: rgba(218, 78, 53, 0.925) !important; }

.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.mw-180px {
  max-width: 180px; }

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.dialog-body {
  background: #fff;
  overflow: unset !important;
  border-radius: 8px; }

.dialog-form {
  max-height: 80vh;
  overflow: auto; }

.paper-container .MuiDialog-paper {
  overflow: unset !important; }

.paper-container.full-width {
  width: calc(100vw - 64px); }

.paper-container.fullWidth {
  width: calc(100vw - 32px); }

.h40 {
  height: 40px; }

.h35 {
  height: 35px; }

.align-center {
  align-items: center; }

.MuiDialogContent-root {
  overflow: unset !important; }
  .MuiDialogContent-root.dialog-overflow {
    overflow: auto !important;
    max-height: 70vh;
    min-height: 50vh; }

.dialog-footer {
  padding: 16px 24px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important; }

.dialog-footer-new {
  padding: 16px 0px !important;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important; }

.dialog-footer-healthOrg {
  padding: 16px 6px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important; }

.text-white {
  color: #fff !important; }

.pl-11 {
  padding-left: 11px !important; }

.MuiIcon-colorDisabled {
  color: #fff !important; }

.MuiIcon-colorError {
  color: #ff5722 !important; }

.MuiPickersToolbar-toolbar {
  background-color: #2196f3 !important; }

.MuiPickersDay-daySelected:hover {
  background-color: #2a80c8 !important; }

.MuiPickersDay-daySelected {
  background-color: #2a80c8 !important; }

.MuiButton-textPrimary {
  color: #2a80c8 !important; }

.input-description {
  font-style: italic !important;
  color: red !important; }

.sidenav__user .username-photo img {
  width: 80px !important;
  height: 80px !important;
  box-shadow: 0 2px 20px -5px #2196f3 !important; }

.sidenav__user .username-photo .username {
  font-weight: 600 !important;
  color: #ffc277 !important;
  font-size: 0.95em !important; }

.highcharts-container {
  /* border-radius: 8px!important; */
  /* box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%); */
  border-radius: 8px;
  border-width: 0;
  background-color: #fff;
  position: relative; }

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #2196f3 !important; }

.MuiPaginationItem-textPrimary.Mui-selected:hover {
  background-color: #1565c0 !important; }

.input-popup-container {
  position: relative !important; }

.input-popup-container button {
  min-width: 100px !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important; }

.content-index {
  padding: 10px 30px;
  background-size: 100% 400px;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  .content-index .index-breadcrumb {
    margin-bottom: 30px; }
  .content-index .index-card.tab {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px; }
    .content-index .index-card.tab .tab-item {
      border-radius: 8px !important; }
      .content-index .index-card.tab .tab-item .tab-container {
        max-width: 100vw !important; }

.w-100 {
  width: 100% !important; }

.n-w {
  white-space: nowrap !important; }

.card-container {
  background: #e4f5fc;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 14px !important; }

.dialog-header {
  border-bottom: 1px solid #e3f2fd !important;
  text-transform: capitalize; }

table .MuiFormControlLabel-root {
  margin-left: auto !important;
  margin-right: auto !important; }

.hidden {
  display: none !important; }

.footer {
  margin-top: 24px; }

.MuiSvgIcon-colorPrimary {
  color: #2196F3 !important; }

@media screen and (max-width: 767px) {
  .MuiButton-startIcon {
    margin: 0 !important; } }

.side-menu-layout .index-breadcrumb {
  margin-bottom: 0 !important; }

.side-menu {
  background: #fff;
  border-radius: 8px; }
  .side-menu .side-menu-item {
    padding: 10px; }

.list-container {
  background: #fff;
  border-radius: 8px; }

.dropdown-container {
  transition: all 0.2s linear;
  background-color: #eaf0ff;
  width: 100%;
  border: 1px solid #3366ff;
  padding: 6px;
  border-radius: 20px; }

.dropdown-container.disappear {
  transition: all 0.2s linear;
  background-color: #eaf0ff;
  width: 100%;
  border: none;
  padding: 0px 6px;
  margin: 0px 8px;
  height: 0;
  z-index: -1;
  opacity: 0; }

.dropdown-children > form {
  width: 100% !important; }

.side-menu-item .btn {
  white-space: normal !important;
  width: 100%; }

.mega-menu .submenu-overflow {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 8px !important;
  position: fixed;
  top: 120px !important;
  left: calc((100vw - 841px)/2);
  z-index: 999999; }

.mega-menu-item {
  text-align: center;
  position: relative; }
  .mega-menu-item ul {
    top: 30px !important;
    left: 0px !important;
    opacity: 1 !important;
    visibility: visible !important; }

.mega-menu-sub-item {
  text-align: left !important; }

.submenu-overflow {
  box-shadow: 0 2px 20px -5px #2196f3 !important;
  border-radius: 8px;
  padding: 8px 0px !important;
  border-top: 3px solid #2196f3;
  top: 40px !important; }

.MuiTab-textColorPrimary.Mui-selected {
  color: var(--primary) !important;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 3px solid var(--primary); }

.MuiTabs-root {
  min-height: unset !important; }

.MuiTab-root {
  min-height: unset !important; }

.border-bottom-radius-8 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.form-title {
  background-color: #2196f3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .form-title h4 {
    color: #fff !important;
    margin: 0 !important; }

.form-content {
  padding-top: 16px !important;
  border: 1px solid #2196f3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 24px !important; }

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #2196f3 !important; }

.table-divider td {
  font-weight: bold; }

.fd-rr {
  flex-direction: row-reverse; }

.paddingLeft {
  padding-top: 15px; }

.MuiInputLabel-shrink {
  transform: translate(0, calc(-75% + 6px)) scale(0.95) !important; }
  .MuiInputLabel-shrink.MuiInputLabel-outlined {
    transform: translate(14px, -6px) scale(0.75) !important; }

.MuiInputLabel-root.MuiFormLabel-root {
  font-size: 16px;
  line-height: 16px;
  white-space: pre-wrap;
  overflow: unset;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 600; }

.medication-bar {
  height: 30px;
  margin: auto 0;
  border-radius: 6px;
  border: 1px solid #b54600;
  background-image: linear-gradient(0deg, #e2860c 0%, #e2860c 33%, #e5cb7a);
  align-items: center;
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  line-height: 22px; }
  .medication-bar:hover {
    background-image: linear-gradient(0deg, #f7e6b5 0%, #f3d09e 33%, #f1ad50); }

.dialog-content-overflow {
  min-height: 8vh;
  max-height: 72vh;
  overflow: auto !important; }

@media screen and (max-width: 768px) {
  .dialog-content-overflow {
    max-height: 56vh; } }

@media screen and (max-height: 500px) {
  .dialog-content-overflow {
    height: calc(100vh - 180px); } }

.dialog-content-overflow-auto {
  overflow: auto !important; }

.validator-form-scroll-dialog {
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important; }

.MuiInput-root.MuiInputBase-root {
  font-size: 0.875rem !important;
  line-height: 1.5em !important; }
  .MuiInput-root.MuiInputBase-root .MuiInput-input.MuiAutocomplete-input {
    padding: 4px 0 5px !important; }

.MuiOutlinedInput-root.MuiInputBase-root {
  font-size: 0.875rem !important;
  line-height: 1.5em !important; }
  .MuiOutlinedInput-root.MuiInputBase-root .MuiOutlinedInput-input.MuiAutocomplete-input {
    padding: 4px 0 5px !important; }

.MuiAutocomplete-paper {
  font-size: 14px !important; }

.dialog-table-content {
  min-height: 70vh !important;
  max-height: 70vh !important;
  overflow: auto !important; }
  .dialog-table-content > div {
    overflow: auto !important;
    min-width: 1270px; }

.MuiFormControlLabel-root {
  margin-right: 0 !important; }

.sideListWrapper .sideList,
.sideListWrapper .formContent {
  transition: all 0.15s ease; }

.sideListWrapper .sideListContainer {
  min-width: 260px; }

.sideListWrapper.close .sideList {
  max-width: 0;
  flex-basis: 0;
  overflow: hidden;
  padding: 0 !important;
  opacity: 0; }

.sideListWrapper.close .formContent {
  max-width: 100% !important;
  flex-basis: 100%; }

.sideListWrapper.close .box-primary-d, .sideListWrapper.close .title-primary-d {
  padding: 0 !important;
  display: none; }

.notification-popup-list-content {
  padding-left: 8px;
  margin-top: 0;
  list-style-type: none; }
  .notification-popup-list-content li.notification-popup-list-content_clickable:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline; }
  .notification-popup-list-content li > ul {
    padding-left: 20px;
    list-style-type: none; }
    .notification-popup-list-content li > ul li:hover {
      cursor: pointer;
      opacity: 0.8;
      text-decoration: underline; }

.MuiFormControlLabel-label.Mui-disabled,
.MuiInputLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.8) !important; }

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.8) !important; }

.MuiChip-root.Mui-disabled {
  opacity: 0.8 !important; }

.MuiInput-input.MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: unset; }

.text-btn-delete {
  color: rgba(218, 78, 53, 0.925); }
  .text-btn-delete:hover {
    cursor: pointer;
    color: rgba(218, 78, 53, 0.8);
    transition: all 0.15s ease; }

.report-index {
  overflow-x: auto; }
  .report-index .report-content {
    min-width: 900px; }

.react-multi-email:hover {
  border-color: rgba(0, 0, 0, 0.87);
  cursor: text; }

.react-multi-email.error {
  border-color: #f44336; }

.field-title-bold {
  font-weight: 600;
  margin-left: 3px; }

.MuiTypography-root.MuiFormControlLabel-label {
  font-weight: 600 !important; }

.MuiFormGroup-root > label.MuiFormLabel-root {
  font-weight: 600 !important;
  color: #000; }

.globits-paging-v2 > .MuiInput-root.MuiInputBase-root.MuiAutocomplete-inputRoot {
  margin-top: 12px; }

.globits-paging-v2 .Mui-error {
  color: #f44336 !important; }
  .globits-paging-v2 .Mui-error::after {
    border-bottom-color: #f44336 !important; }

.globits-paging-v2 input {
  height: 1.1876em; }

.header-container-primary {
  background-color: #338593 !important;
  border-bottom: 1px solid #ced5d7 !important;
  font-size: 20px !important;
  border-radius: 15px !important;
  padding: 6px 20px !important;
  margin-bottom: 12px !important; }

.header-title-primary {
  color: #fff;
  margin: 16px;
  font-weight: 700;
  text-transform: uppercase; }

.tab-container-primary {
  background-color: #338593 !important;
  border-radius: 15px;
  padding: 0 16px 0 20px; }

.tab-container-primary button {
  color: #fff;
  font-size: 18px;
  padding: 0px 19px;
  border-radius: 10px;
  margin: 6px 2px;
  line-height: 30px;
  max-width: 400px; }

.tab-container-primary button:hover {
  color: #000 !important;
  font-weight: 500;
  transition: all 0.25s ease; }

.tab-container-primary .selected {
  color: #000 !important;
  background-color: #fff !important;
  border-radius: 10px;
  font-weight: 500;
  font-size: calc(18px + 2px) !important;
  padding: 0px 19px;
  margin: 7px 0;
  border-bottom: none !important; }

.tab-container-primary-small {
  background-color: #338593 !important;
  border-radius: 15px;
  padding: 0 16px 0 20px; }

.tab-container-primary-small button {
  color: #fff;
  font-size: 14px;
  padding: 0px 19px;
  border-radius: 10px;
  margin: 6px 2px;
  line-height: 30px;
  max-width: 400px; }

.tab-container-primary-small button:hover {
  color: #000 !important;
  font-weight: 500;
  transition: all 0.25s ease; }

.tab-container-primary-small .selected {
  color: #000 !important;
  background-color: #fff !important;
  border-radius: 10px;
  font-weight: 500;
  font-size: calc(14px + 2px) !important;
  padding: 0px 19px;
  margin: 7px 0;
  border-bottom: none !important; }

.tab-container-secondary {
  background-color: #F7BA61 !important;
  border-radius: 8px;
  padding: 6px 20px; }

.tab-container-secondary button {
  color: #000000b3;
  padding: 1px 25px 0px 25px;
  margin: 0px 2px;
  color: #fff;
  font-size: 18px;
  max-width: 365px;
  border-radius: 10px !important; }

.tab-container-secondary button:hover {
  color: #000 !important;
  font-weight: bold;
  transition: all 0.25s ease; }

.tab-container-secondary .selected {
  color: #000 !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  font-weight: 700;
  font-size: 18px !important;
  padding: 1px 25px 0px 25px;
  border-bottom: none !important; }

.tab-container-secondary-small {
  background-color: #F7BA61 !important;
  border-radius: 8px;
  padding: 6px 20px; }

.tab-container-secondary-small button {
  color: #000000b3;
  padding: 1px 25px 0px 25px;
  margin: 0px 2px;
  color: #fff;
  font-size: 14px;
  max-width: 365px;
  border-radius: 10px !important; }

.tab-container-secondary-small button:hover {
  color: #000 !important;
  font-weight: bold;
  transition: all 0.25s ease; }

.tab-container-secondary-small .selected {
  color: #000 !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  font-weight: 700;
  font-size: 14px !important;
  padding: 1px 25px 0px 25px;
  border-bottom: none !important; }

.tab-sticky {
  position: sticky !important;
  top: -1px;
  z-index: 11 !important; }

@media only screen and (max-width: 768px) {
  .tab-container-secondary-small button {
    max-width: 60vw; }
  .tab-container-primary-small button {
    max-width: 60vw; }
  .header-container-primary {
    padding: 6px 0  !important; } }

.label-focus-primary.Mui-focused {
  color: var(--primary) !important; }

.input-focus-primary::after:not(.Mui-error) {
  border-bottom: 2px solid var(--primary) !important; }

.MuiOutlinedInput-notchedOutline legend {
  font-size: 12px; }

.MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall.MuiChip-sizeSmall {
  height: 21px; }

.patientCollapseCheckbox .wrapper {
  min-width: 200px; }

.patientCollapseCheckboxW100 .MuiCollapse-wrapper {
  width: 100%; }

.patientCollapseCheckboxW100 .MuiCollapse-wrapperInner {
  width: 100%; }
