.text-center {
  text-align: center !important;
}

.btn {
  cursor: pointer;
  display: inline-block !important;
  font-weight: 400 !important;
  text-align: center !important;
  // white-space: nowrap !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border: 1px solid transparent !important;
  padding: 4px !important;
  font-size: 0.875rem !important;
  line-height: 1.2 !important;
  border-radius: 2px !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  min-width: unset !important;
  white-space: pre;
  text-transform: uppercase;
}

.btn-primary {
  color: #fff !important;
  background-color: $primary !important;
  border-color: $primary !important;

  &.Mui-disabled {
    opacity: 0.5;
  }
}

.gc-2 {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.title-primary {
  font-size: 1.35em;
  font-weight: 500;
  color: $primary !important;
}

.title-primary-d {
  font-size: 1.35em;
  font-weight: 500;
  color: #0d6674 !important;
  padding: 8px;
  background-color: #d8eef1;
  text-align: center;
}

.box-primary-d {
  border: 1px solid #d8eef1;
  padding: 4px;
}

.btn-primary-d {
  color: #fff !important;
  background-color: #2a80c8 !important;
  border-color: #2a80c8 !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #696969 !important;
}

.btn-secondary:hover {
  color: #fff !important;
  background-color: #000000c0 !important;
  // border-color: #00000030 !important;
}

.btn-success {
  color: #fff !important;
  background-color: #3f923f !important;
  border-color: #3f923f !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-warning {
  color: #fff !important;
  background-color: #ffbe50 !important;
  border-color: #ffbe50 !important;
}

.btn-info {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.btn-light {
  color: #212529 !important;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}

.btn-dark {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-outlined {
  color: $primary !important;
  border-color: $primary !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  padding: 4px 24px !important;
}

.btn-outlined-new {
  color: $primary !important;
  border-color: $primary !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  padding: 7.5px 24px !important;
}

.btn-danger:hover {
  color: #fff !important;
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
}

.btn-warning:hover {
  color: #fff !important;
  background-color: #e0a800 !important;
  border-color: #d39e00 !important;
}

.btn-info:hover {
  color: #fff !important;
  background-color: #138496 !important;
  border-color: #117a8b !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #044450 !important;
  border-color: #044450 !important;
}

.btn-primary-d:hover {
  color: #fff !important;
  background-color: #1f5f94;
  border-color: #1f5f94;
}

.btn-success:hover {
  color: #fff !important;
  background-color: #218838 !important;
  border-color: #1e7e34 !important;
}

.btn-light:hover {
  color: #212529 !important;
  background-color: #e2e6ea !important;
  border-color: #dae0e5 !important;
}

.btn-dark:hover {
  color: #fff !important;
  background-color: #23272b !important;
  border-color: #1d2124 !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.MuiTypography-colorPrimary {
  color: #2196f3 !important;
}

.MuiIcon-colorPrimary {
  color: #2196f3 !important;
}

.text-primary-d2 {
  color: #2564a9 !important;
}

.text-primary {
  color: $primary !important;
}

.bgc-primary-d1 {
  background-color: $primary !important;
}

.brc-primary-l2 {
  border-color: #d2e6f6 !important;
}

.bgc-green-d1 {
  background-color: #36ad82 !important;
}

.bgc-primary {
  background-color: #2196f3 !important;
}

.brc-green-l2 {
  border-color: #d8f0e8 !important;
}

.bgc-warning-d1 {
  background-color: #e19e2e !important;
}

.text-orange-l2 {
  color: #ffc277 !important;
}

.brc-warning-l2 {
  border-color: #fbeacd !important;
}

.bgc-danger-tp1 {
  background-color: rgba(218, 78, 53, 0.925) !important;
}

.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mw-180px {
  max-width: 180px
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dialog-body {
  background: #fff;
  overflow: unset !important;
  border-radius: 8px;
}

.dialog-form {
  max-height: 80vh;
  overflow: auto;
}

.paper-container {

  // width: calc(100vw - 64px);
  .MuiDialog-paper {
    overflow: unset !important;
  }
}

.paper-container.full-width {
  width: calc(100vw - 64px);
}

.paper-container.fullWidth {
  width: calc(100vw - 32px);
}

.h40 {
  height: 40px;
}

.h35 {
  height: 35px;
}

.align-center {
  align-items: center;
}

.MuiDialogContent-root {
  overflow: unset !important;

  &.dialog-overflow {
    overflow: auto !important;
    max-height: 70vh;
    min-height: 50vh;
  }
}

.dialog-footer {
  padding: 16px 24px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.dialog-footer-new {
  padding: 16px 0px !important;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.dialog-footer-healthOrg {
  padding: 16px 6px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.text-white {
  color: #fff !important;
}

.pl-11 {
  padding-left: 11px !important;
}

.MuiIcon-colorDisabled {
  color: #fff !important;
}

.MuiIcon-colorError {
  color: rgb(255, 87, 34) !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #2196f3 !important;
}

.MuiPickersDay-daySelected:hover {
  background-color: #2a80c8 !important;
}

.MuiPickersDay-daySelected {
  background-color: #2a80c8 !important;
}

.MuiButton-textPrimary {
  color: #2a80c8 !important;
}

.input-description {
  font-style: italic !important;
  color: red !important;
}

.sidenav__user .username-photo img {
  width: 80px !important;
  height: 80px !important;
  box-shadow: 0 2px 20px -5px #2196f3 !important;
}

.sidenav__user .username-photo .username {
  font-weight: 600 !important;
  color: #ffc277 !important;
  font-size: 0.95em !important;
}

.highcharts-container {
  /* border-radius: 8px!important; */
  /* box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%); */
  border-radius: 8px;
  border-width: 0;
  background-color: #fff;
  position: relative;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #2196f3 !important;
}

.MuiPaginationItem-textPrimary.Mui-selected:hover {
  background-color: #1565c0 !important;
}

.input-popup-container {
  position: relative !important;
}

.input-popup-container button {
  min-width: 100px !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.content-index {
  // height: 400px;
  padding: 10px 30px;
  background-size: 100% 400px;
  background-repeat: no-repeat;
  // background-image: linear-gradient(
  //   -45deg,
  //   #2196f3 0%,
  //   #2196f3 33%,
  //   #00bfa5 100%
  // );
  background-attachment: fixed;

  .index-breadcrumb {
    margin-bottom: 30px;
  }

  .index-card.tab {
    // background: #fff;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;

    .tab-item {
      // background: #fff !important;
      border-radius: 8px !important;

      .tab-container {
        max-width: 100vw !important;
      }
    }
  }
}

.w-100 {
  width: 100% !important;
}

.n-w {
  white-space: nowrap !important;
}

.card-container {
  background: #e4f5fc;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 14px !important;
}

.dialog-header {
  border-bottom: 1px solid #e3f2fd !important;
  text-transform: capitalize;
}

table .MuiFormControlLabel-root {
  margin-left: auto !important;
  margin-right: auto !important;
}

.hidden {
  display: none !important;
}

.footer {
  // z-index: 1 !important;
  margin-top: 24px;
}

.MuiSvgIcon-colorPrimary {
  color: #2196F3 !important;
}

.MuiButton-startIcon {
  @media screen and (max-width: 767px) {
    margin: 0 !important;
  }
}

.side-menu-layout {
  .index-breadcrumb {
    margin-bottom: 0 !important;
  }
}

.side-menu {
  background: #fff;
  border-radius: 8px;

  .side-menu-item {
    padding: 10px;
  }
}

.list-container {
  background: #fff;
  border-radius: 8px;
  // padding: 24px !important;
}

.dropdown-container {
  transition: all 0.2s linear;
  background-color: #eaf0ff;
  width: 100%;
  border: 1px solid #3366ff;
  padding: 6px;
  border-radius: 20px;
}

.dropdown-container.disappear {
  transition: all 0.2s linear;
  background-color: #eaf0ff;
  width: 100%;
  border: none;
  padding: 0px 6px;
  margin: 0px 8px;
  height: 0;
  z-index: -1;
  opacity: 0;
}

.dropdown-children>form {
  width: 100% !important;
}

.side-menu-item .btn {
  white-space: normal !important;
  width: 100%;
}

.mega-menu .submenu-overflow {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 8px !important;
  position: fixed;
  top: 120px !important;
  left: calc((100vw - 841px)/2);
  z-index: 999999;
}

.mega-menu-item {
  text-align: center;
  position: relative;

  ul {
    top: 30px !important;
    left: 0px !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.mega-menu-sub-item {
  text-align: left !important;
}

.submenu-overflow {
  box-shadow: 0 2px 20px -5px #2196f3 !important;
  border-radius: 8px;
  padding: 8px 0px !important;
  border-top: 3px solid #2196f3;
  top: 40px !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $primary !important;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 3px solid $primary;
}

.MuiTabs-root {
  min-height: unset !important;
}

.MuiTab-root {
  min-height: unset !important;
}

.border-bottom-radius-8 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.form-title {
  background-color: #2196f3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  h4 {
    color: #fff !important;
    margin: 0 !important;
  }
}

.form-content {
  padding-top: 16px !important;
  border: 1px solid #2196f3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 24px !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #2196f3 !important;
}

.table-divider {
  td {
    font-weight: bold;
  }

}

.fd-rr {
  flex-direction: row-reverse;
}

.paddingLeft {
  // margin-left: 8px;
  //  padding-left: 7px;
  padding-top: 15px;
}

.MuiInputLabel-shrink {
  transform: translate(0, calc(-75% + 6px)) scale(0.95) !important;

  &.MuiInputLabel-outlined {
    transform: translate(14px, -6px) scale(0.75) !important;
  }
}

.MuiInputLabel-root.MuiFormLabel-root {
  font-size: 16px;
  line-height: 16px;
  white-space: pre-wrap;
  overflow: unset;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 600;
}

.medication-bar {
  // background-color: #dca847;
  height: 30px;
  margin: auto 0;
  border-radius: 6px;
  border: 1px solid #b54600;
  background-image: linear-gradient(0deg, #e2860c 0%, #e2860c 33%, #e5cb7a);
  // display: flex;
  align-items: center;
  padding: 4px;
  font-size: 12px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  cursor: pointer;
  line-height: 22px;

  &:hover {
    background-image: linear-gradient(0deg, #f7e6b5 0%, #f3d09e 33%, #f1ad50);
  }
}

.dialog-content-overflow {
  min-height: 8vh;
  max-height: 72vh;
  overflow: auto !important;
}

@media screen and (max-width: 768px) {
  .dialog-content-overflow {
    max-height: 56vh;
  }
}

@media screen and (max-height: 500px) {
  .dialog-content-overflow {
    height: calc(100vh - 180px);
  }
}


.dialog-content-overflow-auto {
  overflow: auto !important;
}

.validator-form-scroll-dialog {
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important;
}

.MuiInput-root.MuiInputBase-root{
  font-size: 0.875rem !important;
  line-height: 1.5em !important;

  .MuiInput-input.MuiAutocomplete-input {
    padding: 4px 0 5px !important;
  }
}

.MuiOutlinedInput-root.MuiInputBase-root {
  font-size: 0.875rem !important;
  line-height: 1.5em !important;

  .MuiOutlinedInput-input.MuiAutocomplete-input {
    padding: 4px 0 5px !important;
  }
}

.MuiAutocomplete-paper {
  font-size: 14px !important;
}

.dialog-table-content {
  min-height: 70vh !important;
  max-height: 70vh !important;
  overflow: auto !important;

  &>div {
    overflow: auto !important;
    min-width: 1270px;
  }
}

.MuiFormControlLabel-root {
  margin-right: 0 !important;
}


.sideListWrapper {
  .sideList,
  .formContent {
    transition: all 0.15s ease;
  }
  .sideListContainer {
    min-width: 260px;
  }
  &.close {
    .sideList {
      max-width: 0;
      flex-basis: 0;
      overflow: hidden;
      padding: 0 !important;
      opacity: 0;
    }
  
    .formContent {
      max-width: 100% !important;
      flex-basis: 100%;
    }
  
    .box-primary-d, .title-primary-d {
      padding: 0 !important;
      display: none;
    }
  }
}

.notification-popup-list-content {
  padding-left: 8px;
  margin-top: 0;
  list-style-type: none;
  // li::marker {
  //   content: "-  ";
  // }

  li.notification-popup-list-content_clickable:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
  }

  li>ul {
    padding-left: 20px;
    list-style-type: none;

    // li::marker {
    //   content: "+  ";
    // }

    li:hover {
      cursor: pointer;
      opacity: 0.8;
      text-decoration: underline;
    }
  }
}

.MuiFormControlLabel-label.Mui-disabled,
.MuiInputLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.8) !important;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.8) !important;
}

.MuiChip-root.Mui-disabled {
  opacity: 0.8 !important;
}

.MuiInput-input.MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: unset;
}

.text-btn-delete {
  color: rgba(218, 78, 53, 0.925);
  // float: right;

  &:hover {
    cursor: pointer;
    color: rgba(218, 78, 53, 0.8);
    transition: all 0.15s ease;
  }
}

.report-index {
  overflow-x: auto;
  .report-content {
    min-width: 900px;
  }
}

.react-multi-email {
  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
    cursor: text;
  }
  &.error {
    border-color: #f44336;
  }
}

.field-title-bold{
  font-weight: 600;
  margin-left: 3px;
}

.MuiTypography-root.MuiFormControlLabel-label {
  font-weight: 600!important;
}

.MuiFormGroup-root>label.MuiFormLabel-root {
  font-weight: 600!important;
  color: #000;
}

.globits-paging-v2 {
  &>.MuiInput-root.MuiInputBase-root.MuiAutocomplete-inputRoot{
    margin-top: 12px;
  }
 .Mui-error{
    color: #f44336 !important;
    // border-bottom-color: #f44336 !important;
    &::after{
      border-bottom-color:  #f44336 !important;
    }
  }

}
.globits-paging-v2 input{
  height: 1.1876em;
}

.header-container-primary {
  background-color: $light-primary !important;
  border-bottom: 1px solid #ced5d7 !important;
  font-size: 20px !important;
  border-radius: 15px !important;
  padding: 6px 20px !important;
  margin-bottom: 12px !important;
}

.header-title-primary {
  color: #fff;
  margin: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

@each $tab, $size in (tab-container-primary: 18px,  tab-container-primary-small: 14px) {
  .#{$tab} {
    background-color: $light-primary !important;
    border-radius: 15px;
    padding: 0 16px 0 20px;
 } 
 .#{$tab} button {
    color: #fff;
    font-size: $size;
    padding: 0px 19px;
    border-radius: 10px;
    margin: 6px 2px;
    line-height: 30px;
    max-width: 400px;
 }
 .#{$tab} button:hover {
    color: #000 !important;
    font-weight: 500;
    transition: all 0.25s ease;
 }
 .#{$tab} .selected{
    color: #000 !important;
    background-color: #fff !important;
    border-radius: 10px;
    font-weight: 500;
    font-size: calc(#{$size} + 2px) !important;
    padding: 0px 19px;
    margin: 7px 0;
    border-bottom: none !important;
 }
}


@each $tab, $size in (tab-container-secondary: 18px,  tab-container-secondary-small: 14px) {
  .#{$tab} {
    background-color: $dark-secondary !important;
    border-radius: 8px;
    padding: 6px 20px; 
 }
 .#{$tab} button {
  color: #000000b3;
  padding: 1px 25px 0px 25px;
  margin: 0px 2px;
  color: #fff;
  font-size: $size;
  max-width: 365px;
  border-radius: 10px !important;
 }
 .#{$tab} button:hover {
  color: #000 !important;
  font-weight: bold;
  transition: all 0.25s ease;
 }
 .#{$tab} .selected{
  color: #000 !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  font-weight: 700;
  font-size: $size !important;
  padding: 1px 25px 0px 25px;
  // margin: 7px 0;
  border-bottom: none !important;
 }
}

.tab-sticky {
  position: sticky !important;
  top: -1px;
  z-index: 11 !important;
}

@media only screen and (max-width: 768px){
  .tab-container-secondary-small button {
    max-width: 60vw;
  }
  .tab-container-primary-small button {
    max-width: 60vw;
  }
  .header-container-primary{
    padding: 6px 0  !important;
  }
}

.label-focus-primary.Mui-focused{
  color: $primary !important;
}

  .input-focus-primary::after:not(.Mui-error){
    border-bottom: 2px solid $primary !important;
  }  

.MuiOutlinedInput-notchedOutline legend {
  font-size: 12px;
}

.MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall.MuiChip-sizeSmall{
  height: 21px;
}
.patientCollapseCheckbox{
  // margin-right: 20px;
  .wrapper{
    min-width: 200px;
    label{
      // font-size: 14px !important;
    }
    // margin-top: 7px;
  }
}
.patientCollapseCheckboxW100{
  .MuiCollapse-wrapper{
    width: 100%;
  }
  .MuiCollapse-wrapperInner{
    width: 100%;
  }
}